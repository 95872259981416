import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CAMPAIGN_ROUTE } from "../../app/routes";
import Button from "../../components/Button";
import { SHORT_DATE_FORMAT } from "../../app/constants";
import dateFormat from "dateformat";
import {
  CampaignType,
  formatCampaignAmount,
  getCampaignStatus,
  getCampaignStatusText,
} from "../../app/types/campaign";

const StyledCampaign = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem;
  background: var(--bg);
  border: 1px solid var(--border);
  gap: 2.4rem;

  @media (max-width: 900px) {
    gap: 1.2rem;
    padding: 1.8rem;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  flex: 1;
`;

const Logo = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
`;

const ProjectName = styled.h3`
  font-size: 2.4rem;
  font-weight: 500;
`;

const Item = styled.div<{ $desktopOnly?: boolean }>`
  font-size: 1.8rem;
  font-weight: 400;
  flex: 1;

  @media (max-width: 900px) {
    display: ${(props) => (props.$desktopOnly ? "none" : "block")};
  }
`;

interface Props {
  campaign: CampaignType;
}

const Campaign = ({ campaign }: Props) => {
  const navigate = useNavigate();

  const { ended, upcoming, active } = getCampaignStatus(campaign);

  return (
    <StyledCampaign to={`/${CAMPAIGN_ROUTE}/${campaign.privateUuid}`}>
      <Section>
        <Logo src={campaign.logoUrl} alt={`${campaign.projectName} Logo`} />
        <ProjectName>{campaign.projectName}</ProjectName>
      </Section>
      <Item>{formatCampaignAmount(campaign)}</Item>
      <Item $desktopOnly>{getCampaignStatusText(campaign)}</Item>
      {active && (
        <Item $desktopOnly>{`Ends on ${dateFormat(
          campaign.endTimestamp,
          SHORT_DATE_FORMAT
        )}`}</Item>
      )}
      {upcoming && (
        <Item $desktopOnly>{`Starts on ${dateFormat(
          campaign.startTimestamp,
          SHORT_DATE_FORMAT
        )}`}</Item>
      )}
      {ended && (
        <Item $desktopOnly>{`Ended on ${dateFormat(
          campaign.endTimestamp,
          SHORT_DATE_FORMAT
        )}`}</Item>
      )}
      <Button
        onlyDesktop
        primary
        action={() => {
          navigate(`/${CAMPAIGN_ROUTE}/${campaign.privateUuid}`);
        }}
      >
        View
      </Button>
    </StyledCampaign>
  );
};

export default Campaign;
