import { useQuery } from "@tanstack/react-query";
import { useAuth } from "./use-auth";
import { getCampaignSummary } from "../api";

export default function useCampaignSummary(id?: string) {
  const { signature } = useAuth();
  const {
    data: campaignSummary,
    isLoading: isCampaignsumaryLoading,
    error: campaignSummaryError,
  } = useQuery({
    queryKey: ["campaignSummary", { id }],
    queryFn: async () => getCampaignSummary(id!, signature),
    enabled: !!id,
  });
  return { campaignSummary, isCampaignsumaryLoading, campaignSummaryError };
}
