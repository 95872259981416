import { useSearchParams, useNavigate } from "react-router-dom";
import useConnectTwitter from "../app/hooks/use-connect-twitter";
import { useEffect } from "react";
import { debounce } from "../app/utils";

export default function TwitterLogin() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const code = params.get("code");
  const returnTo = params.get("return") || "/";
  const { connectTwitter } = useConnectTwitter();
  const url = window.location.origin + window.location.pathname;
  const redirectUri = url + `?return=${returnTo}`;

  const debouncedConnect = debounce(() => {
    if (!code) return;
    connectTwitter(
      { code, redirectUri },
      {
        onSuccess: () => navigate(returnTo),
        onError: () => navigate("/"),
      }
    );
  }, 300);

  useEffect(() => {
    debouncedConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!code) {
    navigate("/");
    return null;
  }

  return <></>;
}
