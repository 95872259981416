import { CampaignType } from "../types/campaign";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "./use-auth";
import { getCampaigns } from "../api";

const useCampaigns = ({ page = 0 }: { page?: number } = {}) => {
  const { signature } = useAuth();
  return useQuery<CampaignType[]>({
    queryKey: ["campaigns", { page }],
    queryFn: () => getCampaigns(signature, page),
  });
};

export default useCampaigns;
