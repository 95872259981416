import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CampaignCreationData,
  campaignCreationSchema,
  CampaignType,
} from "../types/campaign";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CHAIN_IDS } from "../constants";
import { useEffect, useState } from "react";
import { postCampaign } from "../api";
import useAuthStore from "../stores/auth-store";
import { useAccount } from "wagmi";
import useTokenInfo from "./use-token-info";
import { useNavigate } from "react-router-dom";
import { CAMPAIGN_ROUTE } from "../routes";

export default function useCreateCampaign() {
  const { address, chainId } = useAccount();
  const { getSignature } = useAuthStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [submittedCampaign, setSubmittedCampaign] = useState<CampaignType>();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(campaignCreationSchema),
    defaultValues: {
      chainId: CHAIN_IDS.Ethereum,
    },
  });

  const selectedChainId = watch("chainId");
  const tokenAddress = watch("tokenAddress");
  const {
    tokenDecimals,
    tokenSymbol,
    isError: tokenInfoError,
  } = useTokenInfo(tokenAddress);

  useEffect(() => {
    if (tokenInfoError)
      setError("tokenAddress", {
        message: "Failed to fetch token information",
      });
    else clearErrors("tokenAddress");
  }, [tokenInfoError, clearErrors, setError]);

  useEffect(() => {
    if (chainId !== selectedChainId) {
      setError("chainId", {
        message: "Please connect your wallet to the selected chain id",
      });
    } else {
      clearErrors("chainId");
      trigger("chainId");
    }
  }, [chainId, selectedChainId, clearErrors, trigger, setError]);

  const { mutate, error, isPending } = useMutation({
    mutationFn: async (data: CampaignCreationData) => {
      if (!address) throw new Error("wallet not connected");
      if (!tokenDecimals) throw new Error("token decimals not fetched");
      const signature = getSignature(address);
      if (!signature) throw new Error("not logged in");

      return postCampaign(data, tokenDecimals, signature);
    },
  });

  const onSubmit = (data: CampaignCreationData) =>
    mutate(data, {
      onSuccess: (campaign: CampaignType) => {
        queryClient.invalidateQueries({ queryKey: ["campaigns"] });
        setSubmittedCampaign(campaign);
        navigate(`/${CAMPAIGN_ROUTE}/${campaign.privateUuid}`);
      },
    });

  return {
    register,
    getValues,
    setValue,
    isValid,
    onSubmit: handleSubmit(onSubmit),
    watch,
    errors,
    isPending,
    submissionError: error,
    submittedCampaign,
    tokenSymbol,
  };
}
