import styled from "styled-components";
import { formatError } from "../app/utils";

export const ErrorText = styled.span`
  color: #f1013d;
  font-size: 1.3rem;
  margin-left: 0.3rem;
`;

export function ErrorMessage({ errors }: { errors: (Error | null)[] }) {
  const error = errors.find((e) => e !== null);
  return error && <ErrorText>{formatError(error)}</ErrorText>;
}

export const NormalText = styled.span`
  font-size: 1.5rem;
`;
