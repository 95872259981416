import { CHAIN_IDS } from "../constants";
import { formatNumber, formatScaled, scale, unscale } from "../utils";
import * as yup from "yup";
import { TweetType } from "./tweet";

const chaninIds = Object.values(CHAIN_IDS);
export const campaignCreationSchema = yup.object({
  description: yup.string().required(),
  projectName: yup.string().required().label("Project name"),
  projectUrl: yup.string().url().required().label("Project URL"),
  logoUrl: yup.string().url().required().label("Logo URL"),
  isPrivate: yup.boolean().required(),
  chainId: yup.number().oneOf(chaninIds).required().label("Chain ID"),
  tokenAddress: yup
    .string<`0x${string}`>()
    .matches(/^0x[a-fA-F0-9]{40}$/)
    .required()
    .label("Token address"),
  startTimestamp: yup.date().required().label("Start time"),
  endTimestamp: yup.date().required().label("End time"),
  amount: yup
    .string()
    .matches(/^[0-9]+$/)
    .required(),
});

export type CampaignCreationData = yup.InferType<typeof campaignCreationSchema>;

export type CampaignStatus =
  | "pending"
  | "invalid"
  | "funded"
  | "finalized"
  | "finalized";

export interface RewardType {
  shillerId: number;
  amount: number;
  id: number;
  createdAt: Date;
  updatedAt: Date;
  campaignId: number;
}

export interface CampaignType extends CampaignCreationData {
  id: number;
  tokenSymbol: string;
  tokenDecimals: number;
  tokenPrice: number;
  createdAt: string;
  updatedAt: string;
  uuid: string;
  privateUuid: string;
  managerId: number;
  fundedAt: Date;
  campaignAddress?: `0x${string}`;
  status: CampaignStatus;
  reward?: RewardType;
  manager: {
    createdAt: string;
    id: number;
    address: string;
    twitterId: string;
    updatedAt: string;
    twitterHandle: string;
  };
}

export interface UserCampaignSummaryType {
  id: number;
  createdAt: string;
  updatedAt: string;
  address: `0x${string}`;
  twitterId: string;
  twitterHandle: string;
  totalTweets: number;
  totalLikes: number;
  totalReplies: number;
  totalImpressions: number;
  totalRetweets: number;
  tweets: TweetType[];
}

export interface CampaignSummaryType {
  users: UserCampaignSummaryType[];
  totalTweets: number;
  totalLikes: number;
  totalReplies: number;
  totalImpressions: number;
  totalRetweets: number;
}

export type CampaignApiData = Omit<
  CampaignType,
  "startTimestamp" | "endTimestamp" | "fundedAt"
> & {
  startTimestamp: string;
  endTimestamp: string;
  fundedAt: string;
};

export function campaignToApiData(
  creationData: CampaignCreationData,
  decimals: number
) {
  return {
    ...creationData,
    startTimestamp: creationData.startTimestamp.toISOString(),
    endTimestamp: creationData.endTimestamp.toISOString(),
    amount: scale(creationData.amount, decimals).toString(),
  };
}

export function campaignFromApiData(apiData: CampaignApiData): CampaignType {
  return {
    ...apiData,
    fundedAt: new Date(apiData.fundedAt),
    startTimestamp: new Date(apiData.startTimestamp),
    endTimestamp: new Date(apiData.endTimestamp),
    reward: apiData.reward
      ? {
          ...apiData.reward,
          createdAt: new Date(apiData.reward.createdAt),
          updatedAt: new Date(apiData.reward.updatedAt),
          amount: apiData.reward.amount,
        }
      : undefined,
  };
}

export function getCampaignStatus(campaign: CampaignType) {
  const ended = campaign.endTimestamp.getTime() < Date.now();
  const upcoming = campaign.startTimestamp.getTime() > Date.now() && !ended;
  const pending = !campaign.campaignAddress;

  return {
    pending,
    ended,
    upcoming,
    active: !pending && !upcoming && !ended,
  };
}

export function getCampaignStatusText(campaign: CampaignType) {
  const { pending, ended, upcoming } = getCampaignStatus(campaign);
  if (ended) return "Ended";
  if (pending) return "Pending";
  if (upcoming) return "Upcoming";
  return "Active";
}

export function getUsdValue(campaign: CampaignType) {
  return unscale(campaign.amount, campaign.tokenDecimals) * campaign.tokenPrice;
}

export function formatCampaignAmount(
  campaign: CampaignType,
  includeUsd: boolean = true
): string {
  const amount = formatScaled(campaign.amount, campaign.tokenDecimals);
  const amountString = `${amount} ${campaign.tokenSymbol}`;
  if (!campaign.tokenPrice || !includeUsd) return amountString;
  const priceString = formatNumber(getUsdValue(campaign), 0);
  return `${amountString} ($${priceString})`;
}
