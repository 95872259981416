import { me } from "../api";
import { useAuth } from "./use-auth";
import { useQuery } from "@tanstack/react-query";

export default function useCurrentUser() {
  const { signature, address } = useAuth();
  const {
    data: user,
    isLoading: userLoading,
    error: userError,
  } = useQuery({
    queryKey: ["user", address],
    queryFn: () => me(signature!),
    enabled: !!signature,
  });

  return { user, userLoading, userError };
}
