import styled from "styled-components";

import ConnectWallet from "./ConnectWallet";
import Logo from "./Logo";

const StyledHeader = styled.header`
  width: 100%;
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bg);
  border: 1px solid var(--border);
  margin-bottom: 4.8rem;
`;

const Header = () => {
  return (
    <StyledHeader>
      <Logo />
      <ConnectWallet />
    </StyledHeader>
  );
};

export default Header;
