import Button from "../../components/Button";
import { CampaignType, getUsdValue } from "../../app/types/campaign";
import Container from "./Container";

interface Props {
  campaign: CampaignType;
  balance: number;
}

const Claim = ({ campaign, balance }: Props) => {
  return (
    <Container.SpacedWrapper>
      <Container.Header>{`Claim ${balance.toLocaleString()} ${
        campaign.tokenSymbol
      } ($${getUsdValue(campaign)})`}</Container.Header>
      <Button action={() => alert("Claimed!")} primary>
        Claim
      </Button>
    </Container.SpacedWrapper>
  );
};

export default Claim;
