import { forwardRef } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--bg);
  padding: 4.8rem;
  border: 1px solid var(--border);
  gap: 4.8rem;

  @media (max-width: 900px) {
    padding: 2.4rem;
    gap: 2.4rem;
  }
`;

const Container = forwardRef<HTMLDivElement, any>(
  ({ children, ...otherProps }, ref) => (
    <StyledContainer {...otherProps} ref={ref}>
      {children}
    </StyledContainer>
  )
);

const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 2rem;
    text-align: center;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

const SpacedWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;

  @media (max-width: 900px) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`;

export default Object.assign(Container, { Header, Wrapper, SpacedWrapper });
