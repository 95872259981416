export const LIVE = false;
export const DATE_FORMAT = "dS mmm yyyy - h:MM TT";
export const SHORT_DATE_FORMAT = "dS mmm yyyy";
export const BASE_API = process.env.REACT_APP_API_BASE_URL;
export const SUPPORTED_CHAINS: string[] = [
  "Ethereum",
  "Optimism",
  "Arbitrum",
  "Base",
  "Anvil",
];
export const CHAIN_IDS: Record<string, number> = {
  Ethereum: 1,
  Optimism: 10,
  Arbitrum: 42161,
  Base: 8453,
  Anvil: 31337,
};
export const REVERSE_CHAIN_IDS: Record<number, string> = Object.fromEntries(
  Object.entries(CHAIN_IDS).map(([k, v]) => [v, k])
);
export const CHAIN_ICONS: Record<string, string> = {
  Ethereum: "https://synthetixio.github.io/synthetix-assets/markets/ETH.svg",
  Optimism: "https://synthetixio.github.io/synthetix-assets/markets/OP.svg",
  Arbitrum: "https://synthetixio.github.io/synthetix-assets/markets/ARB.svg",
  Base: "https://raw.githubusercontent.com/base-org/brand-kit/refs/heads/main/logo/symbol/Base_Symbol_Blue.svg",
  Anvil: "https://synthetixio.github.io/synthetix-assets/markets/ETH.svg",
};

export const messageToSign = `By signing this message, I agree to the terms and conditions and privacy policy of Shillio.`;

export const transactionRejectedErrorMessage = "User rejected the request.";

export const twitterLoginUrl =
  "https://x.com/i/oauth2/authorize?response_type=code&client_id=akpzSWVLSHU3OUlTQXFSZkZpdGo6MTpjaQ&scope=tweet.read+users.read+offline.access&state=state&code_challenge=challenge&code_challenge_method=plain";
