import styled from "styled-components";

const StyledNotFoundPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 130rem;
  flex: 1;
  padding: 10rem 0;
`;

const Header = styled.h1`
  font-size: 3.6rem;
  font-weight: 500;
  margin-top: 4.8rem;
`;

const NotFoundPage = () => {
  return (
    <StyledNotFoundPage>
      <Header>404 - Page Not Found</Header>
    </StyledNotFoundPage>
  );
};

export default NotFoundPage;
