import styled from "styled-components";

export const StyledInput = styled.input`
  height: 4.8rem;
  padding: 0 2.4rem;
  font-size: 1.6rem;
  font-weight: 400;
  background: var(--bg);
  color: var(--main);
  border: 1px solid var(--border);
  flex: 1;

  &:focus {
    outline: none;
    border-color: var(--main);
  }

  &::placeholder {
    color: var(--sub);
  }
`;

interface Props {
  placeholder: string;
  value: string;
  setValue: (value: string) => void;
  type?: string;
}

const Input = ({ placeholder, value, setValue, type }: Props) => {
  return (
    <StyledInput
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      type={type || "text"}
    />
  );
};

export default Input;
