import { useCallback, useEffect, useState } from "react";
import { UserCampaignSummaryType } from "../types/campaign";
import { RewardsCreationData } from "../types/rewards";

export enum DistributionType {
  Even = "Even",
  Impressions = "Impressions",
  Likes = "Likes",
}

export default function useDistribution(
  rawTotalAmount?: string,
  users?: UserCampaignSummaryType[]
) {
  const [distribution, setDistribution] = useState(DistributionType.Even);
  const [eligibleAccounts, setEligibleAccounts] = useState<string[]>([]);
  const [rewards, setRewards] = useState<RewardsCreationData[]>([]);
  const eligibleUsers = users?.filter((u) =>
    eligibleAccounts.includes(u.twitterHandle)
  );

  useEffect(() => {
    if (!users?.length) return;
    setEligibleAccounts(users?.map((user) => user.twitterHandle));
  }, [users]);

  const getValue = useCallback(
    (user: UserCampaignSummaryType) => {
      switch (distribution) {
        case DistributionType.Even:
          return 1;
        case DistributionType.Impressions:
          return user.totalImpressions;
        case DistributionType.Likes:
          return user.totalLikes;
      }
    },
    [distribution]
  );

  useEffect(() => {
    if (!eligibleUsers?.length || !rawTotalAmount) return;
    const totalAmount = BigInt(rawTotalAmount);
    const total = eligibleUsers.reduce((acc, curr) => acc + getValue(curr), 0);
    let rewards: RewardsCreationData[] = [];
    let currentAmount = 0n;
    for (let i = 0; i < eligibleUsers.length; i++) {
      const userShare = getValue(eligibleUsers[i]) / total;
      const amount =
        i === eligibleUsers.length - 1
          ? totalAmount - currentAmount
          : (totalAmount * BigInt(Math.round(userShare * 10 ** 6))) / 10n ** 6n;
      currentAmount += amount;
      rewards.push({ shillerId: eligibleUsers[i].id, amount });
    }
    setRewards(rewards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distribution, getValue, eligibleUsers?.length, rawTotalAmount]);

  const getReward = (user: UserCampaignSummaryType) => {
    return rewards.find((r) => r.shillerId === user.id)?.amount ?? 0n;
  };

  const isEligible = (user: UserCampaignSummaryType) => {
    return rewards.some((r) => r.shillerId === user.id);
  };

  const toggleEligible = (user: UserCampaignSummaryType) => {
    const eligible = isEligible(user);
    const newList = eligible
      ? eligibleAccounts.filter((a) => a !== user.twitterHandle)
      : [...eligibleAccounts, user.twitterHandle];
    setEligibleAccounts(newList);
  };

  return {
    rewards,
    getReward,
    distribution,
    setDistribution,
    eligibleAccounts,
    isEligible,
    toggleEligible,
  };
}
