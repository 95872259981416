import { CampaignApiData, campaignFromApiData, CampaignType } from "./campaign";
import { UserType } from "./user";

export interface TweetType {
  id: number;
  campaignId: number;
  tweetId: string;
  shillerId: number;
  timestamp: Date;
  impressionsCount: number;
  likesCount: number;
  retweetsCount: number;
  repliesCount: number;
  campaign: CampaignType;
  shiller: UserType;
}

type TweetApiData = Omit<TweetType, "timestamp" | "campaign"> & {
  timestamp: string;
  campaign: CampaignApiData;
};

export function tweetFromApiData(apiData: TweetApiData): TweetType {
  return {
    ...apiData,
    timestamp: new Date(apiData.timestamp),
    campaign: campaignFromApiData(apiData.campaign),
  };
}

export function tweetUrl(tweet: TweetType) {
  return `https://x.com/${tweet.shiller.twitterHandle}/status/${tweet.tweetId}`;
}
