import { erc20Abi, maxUint256 } from "viem";
import {
  useAccount,
  useReadContract,
  useTransactionReceipt,
  useWriteContract,
} from "wagmi";
import useAddresses from "./use-addresses";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

export default function useApproval(tokenAddress?: `0x${string}`) {
  const { address } = useAccount();
  const addresses = useAddresses();
  const queryClient = useQueryClient();

  const {
    data,
    writeContract,
    isPending: isApprovePending,
    error: approveError,
  } = useWriteContract();

  const { data: receipt } = useTransactionReceipt({ hash: data });

  const {
    data: approvedAmount,
    isPending: isAllowancePending,
    error: allowanceError,
    queryKey,
  } = useReadContract({
    abi: erc20Abi,
    address: tokenAddress,
    functionName: "allowance",
    args: [address!, addresses?.factory!],
    query: { enabled: address && !!addresses },
  });

  useEffect(() => {
    if (receipt?.status === "success")
      queryClient.invalidateQueries({ queryKey });
  }, [receipt?.status, queryKey, queryClient]);

  const approve = () => {
    if (!addresses || !tokenAddress) return;

    writeContract(
      {
        abi: erc20Abi,
        address: tokenAddress,
        functionName: "approve",
        args: [addresses.factory, maxUint256],
      },
      {
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }
    );
  };

  const isApproved = (amount?: bigint | string) => {
    return (
      approvedAmount !== undefined &&
      amount !== undefined &&
      approvedAmount >= BigInt(amount)
    );
  };

  return {
    isApproved,
    approvedAmount,
    isAllowancePending,
    allowanceError,
    approve,
    isApprovePending,
    approveError,
  };
}
