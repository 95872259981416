import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { DISTRIBUTE_ROUTE } from "../../app/routes";
import { CampaignType } from "../../app/types/campaign";
import Container from "./Container";

interface Props {
  campaign: CampaignType;
}

const Distribute = ({ campaign }: Props) => {
  const navigate = useNavigate();

  return (
    <Container.SpacedWrapper>
      <Container.Header>
        {`Your campaign has finished, it's now time to distribute the ${campaign.tokenSymbol} tokens to the participants.`}
      </Container.Header>
      <Button
        action={() => navigate(`/${DISTRIBUTE_ROUTE}/${campaign.privateUuid}`)}
        primary
      >
        {`Distribute ${campaign.tokenSymbol}`}
      </Button>
    </Container.SpacedWrapper>
  );
};

export default Distribute;
