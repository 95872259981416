import useAuthStore from "../stores/auth-store";
import { useAccount } from "wagmi";

export function useAuth() {
  const { isConnected, address } = useAccount();
  const { getSignature } = useAuthStore();
  const signature = address && getSignature(address);

  return {
    signature,
    address,
    walletConnected: isConnected,
    loggedIn: !!signature,
  };
}
