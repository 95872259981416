import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./use-auth";
import { RewardsCreationData } from "../types/rewards";
import { CampaignType } from "../types/campaign";
import { distributeRewards as sendDistributeRewards } from "../api";

export default function useDistributeRewards(campaign?: CampaignType) {
  const { signature } = useAuth();
  const queryClient = useQueryClient();
  const {
    mutate: distributeRewards,
    isPending: isDistributingRewardsPending,
    error: distributeRewardsError,
  } = useMutation({
    mutationFn: (rewards: RewardsCreationData[]) => {
      if (!signature) throw new Error("Not logged in");
      if (!campaign) throw new Error("No campaign provided");
      return sendDistributeRewards(campaign.privateUuid, rewards, signature);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["campaign", { id: campaign?.privateUuid }],
      }),
  });

  return {
    distributeRewards,
    isDistributingRewardsPending,
    distributeRewardsError,
  };
}
