import { useAccount } from "wagmi";
import { ADDRESSES, AddressesType } from "../addresses";

const useAddresses = (): AddressesType | null => {
  const { chainId } = useAccount();
  if (!chainId) return null;
  const address = ADDRESSES[chainId];
  if (!address) return null;
  return address;
};

export default useAddresses;
