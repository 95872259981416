import { create } from "zustand";
import { persist } from "zustand/middleware";

interface AuthState {
  signatures: Record<string, string>;
  registerSignature: (address: string, signature: string) => void;
  getSignature: (address: string) => string | undefined;
}

const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      signatures: {},
      getSignature: (address: string) => get().signatures[address],
      registerSignature: (address: string, signature: string) =>
        set({ signatures: { ...get().signatures, [address]: signature } }),
    }),
    {
      name: "shillio-auth-storage",
    }
  )
);

export default useAuthStore;
