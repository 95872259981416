import styled from "styled-components";
import Campaign from "./Campaign";
import useCampaigns from "../../app/hooks/use-campaigns";

const StyledCampaings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2.4rem;
  background: var(--bg);
  border: 1px solid var(--border);

  @media (max-width: 900px) {
    padding: 1.2rem;
  }
`;

const Header = styled.h2`
  font-size: 3.6rem;
  font-weight: 500;
  margin-bottom: 2.4rem;

  @media (max-width: 900px) {
    font-size: 3rem;
  }
`;

const CampaignList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  width: 100%;
`;

const Campaigns = () => {
  const { data: campaigns } = useCampaigns();

  return (
    <StyledCampaings>
      <Header>Campaigns</Header>
      <CampaignList>
        {campaigns
          ? campaigns.map((campaign, index) => (
              <Campaign key={index} campaign={campaign} />
            ))
          : "Loading..."}
      </CampaignList>
    </StyledCampaings>
  );
};

export default Campaigns;
