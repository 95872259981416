import { ContractFunctionExecutionError, getAddress } from "viem";
import { transactionRejectedErrorMessage } from "./constants";

export function scale(
  value: number | string | bigint,
  decimals: number
): bigint {
  return BigInt(Number(value) * 10 ** 6) * 10n ** (BigInt(decimals) - 6n);
}

export function unscale(value: bigint | string, decimals: number): number {
  return (
    Number(BigInt(value) / BigInt(10n ** (BigInt(decimals) - 6n))) / 10 ** 6
  );
}

export const round = (value: number, decimals: number): number => {
  return Number(value.toFixed(decimals));
};

export function formatNumber(number: number, decimals = 2): string {
  return round(number, decimals).toLocaleString();
}

export function formatScaled(value: bigint | string, decimals: number): string {
  return formatNumber(unscale(value, decimals));
}

const extractIdRegex =
  /https:\/\/(?:twitter|x)\.com\/[^\\/]+\/status\/([0-9]+)/;

export function getTweetId(tweetURL: string) {
  const match = extractIdRegex.exec(tweetURL);
  if (!match) throw new Error("invalid URL");
  return match[1];
}

export function shortenString(str: string) {
  return str.substring(0, 6) + "..." + str.substring(str.length - 4);
}

export function shortenAddress(address: `0x${string}`) {
  return shortenString(getAddress(address));
}

export function uuidToBigint(uuid: string): bigint {
  return BigInt("0x" + uuid.replaceAll("-", ""));
}

export function getErrorMessage(error: Error) {
  if (error instanceof ContractFunctionExecutionError) {
    const message = error.shortMessage;
    // rejecting the transaction is not really an error so we just ignore it
    if (message === transactionRejectedErrorMessage) return "";
    return message;
  }
  return error.message;
}

export function formatError(error: Error) {
  const errorMessage = getErrorMessage(error);
  if (errorMessage) {
    console.error(error);
    return `${errorMessage} Check the console for more information`;
  }
  return "";
}

export function debounce(f: any, delay: number) {
  let handle: ReturnType<typeof setTimeout> | null = null;
  return (...args: any[]) => {
    if (handle) clearTimeout(handle);
    return new Promise((resolve) => {
      handle = setTimeout(() => {
        resolve(f(...args));
      }, delay);
    });
  };
}
