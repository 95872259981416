import useAuthStore from "../app/stores/auth-store";
import Button from "./Button";
import { messageToSign } from "../app/constants";
import { login } from "../app/api";
import { useAccount, useConnect, useSignMessage } from "wagmi";
import { shortenAddress } from "../app/utils";
import useCurrentUser from "../app/hooks/use-current-user";
import { useEffect } from "react";

interface Props {
  hideWhenDone?: boolean;
  wide?: boolean;
}

const ConnectWallet = ({ hideWhenDone, wide }: Props) => {
  const { connect, connectors } = useConnect();
  const { address, isConnected } = useAccount();

  const { getSignature, registerSignature } = useAuthStore();
  const { userError } = useCurrentUser();

  const signature = address && getSignature(address);
  const { signMessageAsync } = useSignMessage();

  const askForSignature = async () => {
    if (!address) return;

    const signature = await signMessageAsync({
      account: address,
      message: messageToSign,
    });
    registerSignature(address, signature);
    await login(signature);
  };

  // If the user signed but we somehow did not register him, try to login again
  useEffect(() => {
    if (signature && userError?.message?.includes("401")) {
      login(signature);
    }
  }, [signature, userError]);

  if (hideWhenDone && signature) return null;

  return (
    <Button
      wide={wide}
      primary={!isConnected || !signature}
      action={() => {
        if (!isConnected) connect({ connector: connectors[0] });
        if (!signature) askForSignature();
      }}
    >
      {signature
        ? shortenAddress(address)
        : address
        ? `Login as ${shortenAddress(address)}`
        : "Connect"}
    </Button>
  );
};

export default ConnectWallet;
