import styled from "styled-components";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { CampaignType } from "../../app/types/campaign";
import usePostTweet from "../../app/hooks/use-post-tweet";
import { ErrorText } from "../../components/utils";
import { useAuth } from "../../app/hooks/use-auth";
import useCurrentUser from "../../app/hooks/use-current-user";
import { twitterLoginUrl } from "../../app/constants";
import useIsLoggedIn from "../../app/hooks/use-is-logged-in";
import ConnectWallet from "../../components/ConnectWallet";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 2.4rem;
`;

const StyledSubmit = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2.4rem;

  @media (max-width: 900px) {
    gap: 1.2rem;
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

interface Props {
  campaign: CampaignType;
}

const Submit = ({ campaign }: Props) => {
  const { loggedIn } = useAuth();
  const { user } = useCurrentUser();
  const twitterLoggedIn = !!user?.twitterId;
  const {
    postTweet,
    tweetId,
    tweetUrl,
    urlError,
    setTweetUrl,
    error,
    isPending,
  } = usePostTweet(campaign);
  const path = window.location.pathname;
  const url = encodeURIComponent(`http://localhost:3000/login?return=${path}`);
  const isLoggedIn = useIsLoggedIn();

  const onClick = () => {
    postTweet();
  };

  return (
    <Container>
      <Header>Submit Post for Campaign</Header>
      <StyledSubmit>
        {twitterLoggedIn && isLoggedIn && (
          <>
            <InputContainer>
              <Input
                placeholder="Enter tweet link"
                value={tweetUrl}
                setValue={setTweetUrl}
              />
            </InputContainer>
            <Button
              disabled={!loggedIn || isPending || !tweetId}
              action={onClick}
              primary
            >
              Submit Post
            </Button>
          </>
        )}
        {isLoggedIn && !twitterLoggedIn && (
          <Button wide link={`${twitterLoginUrl}&redirect_uri=${url}`}>
            Connect X
          </Button>
        )}
        {!isLoggedIn && <ConnectWallet wide hideWhenDone />}
      </StyledSubmit>
      {error && <ErrorText>{error.message}</ErrorText>}
      {urlError && <ErrorText>{urlError}</ErrorText>}
    </Container>
  );
};

export default Submit;
