import { useMutation } from "@tanstack/react-query";
import { registerTwitter } from "../api";
import { useAuth } from "./use-auth";

type MutationArgs = { code: string; redirectUri: string };

export default function useConnectTwitter() {
  const { signature } = useAuth();
  const {
    mutate: connectTwitter,
    error: connectTwitterError,
    isPending: isConnectTwitterPending,
  } = useMutation({
    mutationFn: ({ code, redirectUri }: MutationArgs) => {
      if (!signature) throw new Error("Not logged in");
      return registerTwitter(code, redirectUri, signature);
    },
  });
  return { connectTwitter, connectTwitterError, isConnectTwitterPending };
}
