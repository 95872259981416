import { useQuery } from "@tanstack/react-query";
import { useAuth } from "./use-auth";
import { getCampaign } from "../api";

const useCampaign = (id?: string) => {
  const { signature } = useAuth();
  const {
    data: campaign,
    isLoading: isCampaignLoading,
    error: campaignError,
  } = useQuery({
    queryKey: ["campaign", { id }],
    queryFn: async () => getCampaign(id!, signature),
    enabled: !!id,
  });
  return { campaign, isCampaignLoading, campaignError };
};

export default useCampaign;
