import { useAccount } from "wagmi";
import useAuthStore from "../stores/auth-store";

const useIsLoggedIn = () => {
  const { address } = useAccount();

  const { getSignature } = useAuthStore();

  const signature = address && getSignature(address);
  return !!signature;
};

export default useIsLoggedIn;
