import styled from "styled-components";
import Logo from "./Logo";
import { DOCS_LINK, TELEGRAM_LINK, TWITTER_LINK } from "../app/links";

interface FooterLink {
  url: string;
  text: string;
}

const FOOTER_LINKS: FooterLink[] = [
  {
    url: TWITTER_LINK,
    text: "Twitter",
  },
  {
    url: TELEGRAM_LINK,
    text: "Telegram",
  },
  {
    url: DOCS_LINK,
    text: "Docs",
  },
];

const StyledFooter = styled.header`
  width: 100%;
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bg);
  border: 1px solid var(--border);
  margin-top: 4.8rem;
`;

const Links = styled.div`
  display: flex;
  gap: 2.4rem;
`;

const Link = styled.a`
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--text);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Logo />
      <Links>
        {FOOTER_LINKS.filter((link) => link.url !== "TODO").map((link) => (
          <Link key={link.url} href={link.url} target="_blank" rel="noreferrer">
            {link.text}
          </Link>
        ))}
      </Links>
    </StyledFooter>
  );
};

export default Footer;
