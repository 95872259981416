import styled from "styled-components";
import { LIVE } from "../app/constants";

const StyledComingSoon = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
`;

const Logo = styled.img`
  height: 17rem;
  border-radius: 50%;
`;

const ComingSoonText = styled.h1`
  font-size: 6rem;
  color: var(--main);
`;

const ComingSoon = () => {
  if (LIVE) return null;

  return (
    <StyledComingSoon>
      <Content>
        <Logo src="/assets/logo.jpg" />
        <ComingSoonText>Coming Soon</ComingSoonText>
      </Content>
    </StyledComingSoon>
  );
};

export default ComingSoon;
