import { erc20Abi } from "viem";
import { useReadContracts } from "wagmi";

export default function useTokenInfo(tokenAddress?: `0x${string}`) {
  const { data, error, isError } = useReadContracts({
    contracts: [
      {
        abi: erc20Abi,
        address: tokenAddress,
        functionName: "decimals",
      },
      {
        abi: erc20Abi,
        address: tokenAddress,
        functionName: "symbol",
      },
    ],
  });
  const [decimalsCall, symbolCall] = data || [];
  return {
    tokenDecimals: decimalsCall?.result,
    tokenSymbol: symbolCall?.result,
    error,
    isError:
      isError ||
      decimalsCall?.status === "failure" ||
      symbolCall?.status === "failure",
  };
}
