import styled, { keyframes } from "styled-components";

import cross from "../assets/cross.svg";
import { useEffect, useState } from "react";

const CROSS_COUNT = 60;

const StyledBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url("/assets/background.svg");
  background-size: 12rem;

  @media (max-width: 900px) {
    background-size: 9rem;
  }
`;

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  2% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
`;

const Cross = styled.img<{ x: number; y: number; $delay: number }>`
  position: absolute;
  top: ${({ y }) => `calc(${y} * 12rem)`};
  left: ${({ x }) => `calc(${x} * 12rem)`};
  height: 1.5rem;
  transform: translate(calc(-50% + 1px), calc(-50% + 1px));
  opacity: 0;

  animation: ${fadeInOut} 120s ${({ $delay }) => $delay}s infinite;

  @media (max-width: 900px) {
    top: ${({ y }) => `calc(${y} * 9rem)`};
    left: ${({ x }) => `calc(${x} * 9rem)`};
  }
`;

const Background = () => {
  const width = Math.ceil((window as any).innerWidth / 120);
  const height = Math.ceil((window as any).innerHeight / 120);

  const [crosses, setCrosses] = useState<
    { x: number; y: number; $delay: number }[]
  >([]);

  useEffect(() => {
    const newCrosses = Array.from({ length: CROSS_COUNT }, () => ({
      x: Math.floor(Math.random() * width),
      y: Math.floor(Math.random() * height),
      $delay: Math.random() * 60,
    }));

    setCrosses(newCrosses);
  }, [width, height]);

  return (
    <StyledBackground>
      <Grid />
      {crosses.map((crossData, index) => (
        <Cross key={index} {...crossData} src={cross} />
      ))}
    </StyledBackground>
  );
};

export default Background;
