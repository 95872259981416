import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import dateFormat from "dateformat";
import { DATE_FORMAT } from "../../app/constants";
import Submit from "./Submit";
import Submissions from "./Submissions";
import Claim from "./Claim";
import Distribute from "./Distribute";
import useCampaign from "../../app/hooks/use-campaign";
import {
  getCampaignStatus,
  getCampaignStatusText,
  getUsdValue,
} from "../../app/types/campaign";
import { formatNumber, formatScaled, shortenAddress } from "../../app/utils";
import { useAccount } from "wagmi";
import Container from "./Container";
import Fund from "./Fund";
import useChainData from "../../app/hooks/use-chain-data";

const StyledCampaignPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.8rem;

  @media (max-width: 900px) {
    gap: 2.4rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;

  @media (max-width: 900px) {
    gap: 1.2rem;
  }
`;

const Icon = styled.img`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;

  @media (max-width: 900px) {
    width: 5rem;
    height: 5rem;
  }
`;

const Header = styled.h1`
  font-size: 3.6rem;
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 3rem;
  }
`;

const SectionSplit = styled.div`
  width: 100%;
  display: flex;
  gap: 2.4rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Section = styled.div<{ $flex: number }>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.$flex};
  gap: 1.2rem;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

const Description = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;

  @media (max-width: 900px) {
    gap: 0.6rem;
  }
`;

const InfoHeader = styled.h2`
  font-size: 1.8rem;
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 1.7rem;
  }
`;

const Info = styled.p`
  font-size: 1.8rem;
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: 1.7rem;
  }
`;

const Link = styled.a`
  text-decoration: underline;
  font-size: 1.8rem;
  font-weight: 400;
  cursor: pointer;
  color: var(--primary);
  padding: 1px 3px;
`;

const CampaignPage = () => {
  const campaignId = useParams().campaignId!;
  const navigate = useNavigate();
  const { address } = useAccount();
  const { campaign, isCampaignLoading, campaignError } =
    useCampaign(campaignId);
  const chainData = useChainData(campaign?.chainId);

  if (campaignError?.message.includes("404")) {
    navigate("/404");
    return null;
  }

  if (isCampaignLoading || !campaign) return <div>Loading...</div>;

  const { ended, upcoming, active } = getCampaignStatus(campaign);

  const admin = campaign.manager.address === address;

  return (
    <StyledCampaignPage>
      {admin && ended && (
        <Container>
          <Distribute campaign={campaign} />
        </Container>
      )}

      <Container>
        <HeaderContainer>
          <Icon src={campaign.logoUrl} alt="Logo" />
          <Header>{`${campaign.projectName} Campaign`}</Header>
        </HeaderContainer>
        <SectionSplit>
          <Section $flex={1}>
            <InfoRow>
              <InfoHeader>Project Website:</InfoHeader>
              <Link href={campaign.projectUrl} target="_blank">
                {campaign.projectUrl}
              </Link>
            </InfoRow>
            <InfoRow>
              <InfoHeader>Status:</InfoHeader>
              <Info>{getCampaignStatusText(campaign)}</Info>
            </InfoRow>
            {upcoming && (
              <InfoRow>
                <InfoHeader>Start Date:</InfoHeader>
                <Info>{dateFormat(campaign.startTimestamp, DATE_FORMAT)}</Info>
              </InfoRow>
            )}
            {active && (
              <InfoRow>
                <InfoHeader>End Date:</InfoHeader>
                <Info>{dateFormat(campaign.endTimestamp, DATE_FORMAT)}</Info>
              </InfoRow>
            )}
            {ended && (
              <InfoRow>
                <InfoHeader>Ended:</InfoHeader>
                <Info>{dateFormat(campaign.endTimestamp, DATE_FORMAT)}</Info>
              </InfoRow>
            )}
            <InfoRow>
              <InfoHeader>Amount:</InfoHeader>
              <Info>{`${formatScaled(
                campaign.amount,
                campaign.tokenDecimals
              )} ${campaign.tokenSymbol}`}</Info>
            </InfoRow>
            {campaign.tokenPrice && (
              <InfoRow>
                <InfoHeader>Campaign Value:</InfoHeader>
                <Info>{`$${formatNumber(getUsdValue(campaign))}`}</Info>
              </InfoRow>
            )}
            {campaign.campaignAddress && chainData && (
              <InfoRow>
                <InfoHeader>Contract:</InfoHeader>
                <Link
                  href={`${chainData.explorer}/address/${campaign.campaignAddress}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "var(--primary)" }}
                >
                  {shortenAddress(campaign.campaignAddress)}
                </Link>
              </InfoRow>
            )}
          </Section>
          <Section $flex={1}>
            <DescriptionContainer>
              {campaign.description.split("\n").map((paragraph, index) => (
                <Description key={index}>{paragraph}</Description>
              ))}
            </DescriptionContainer>
          </Section>
        </SectionSplit>
      </Container>

      {admin && <Fund campaign={campaign} />}

      {campaign && campaign.reward && campaign.reward.amount > 0 && (
        <Container>
          <Claim campaign={campaign} balance={campaign.reward.amount} />
        </Container>
      )}
      {active && !admin && (
        <Container>
          <Submit campaign={campaign} />
        </Container>
      )}
      {!upcoming && (
        <Container>
          <Submissions campaign={campaign} />
        </Container>
      )}
    </StyledCampaignPage>
  );
};

export default CampaignPage;
