import { erc20Abi } from "viem";
import { useAccount, useReadContract } from "wagmi";

export default function useTokenBalance(
  tokenAddress?: `0x${string}`,
  account?: `0x${string}`
) {
  const { address } = useAccount();
  return useReadContract({
    abi: erc20Abi,
    address: tokenAddress,
    functionName: "balanceOf",
    args: [(address || account)!],
    query: { enabled: !!(address || account) },
  });
}
