import { BASE_API } from "./constants";
import {
  CampaignCreationData,
  campaignFromApiData,
  CampaignSummaryType,
  campaignToApiData,
  CampaignType,
} from "./types/campaign";
import { RewardsCreationData } from "./types/rewards";
import { TweetType } from "./types/tweet";
import { UserType } from "./types/user";

const CAMPAIGNS_PER_PAGE = 20;

function makeCommonHeaders(signature?: string) {
  const headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (signature) headers.Authorization = signature;
  return headers;
}

async function apiRequest(path: string, init: RequestInit, signature?: string) {
  const headers = { ...makeCommonHeaders(signature), ...init.headers };
  const response = await fetch(`${BASE_API}${path}`, { ...init, headers });
  if (!response.ok) {
    throw new Error(`Failed to fetch ${path}: ${response.status}`);
  }
  return response.json();
}

export async function me(signature: string): Promise<UserType> {
  return apiRequest("me", {}, signature);
}

export async function registerTwitter(
  code: string,
  redirectUri: string,
  signature: string
): Promise<UserType> {
  return apiRequest(
    "auth/twitter",
    { method: "POST", body: JSON.stringify({ code, redirectUri }) },
    signature
  );
}

export async function getCampaigns(
  signature?: string,
  page?: number
): Promise<CampaignType[]> {
  const path = `campaigns?page=${page || 0}&limit=${CAMPAIGNS_PER_PAGE}`;
  const campaigns = await apiRequest(path, {}, signature);
  return campaigns.map(campaignFromApiData);
}

export async function getCampaign(
  uuid: string,
  signature?: string
): Promise<CampaignType> {
  const campaign = await apiRequest(`campaigns/${uuid}`, {}, signature);
  return campaignFromApiData(campaign);
}

export async function getCampaignSummary(
  uuid: string,
  signature?: string
): Promise<CampaignSummaryType> {
  return apiRequest(`campaigns/${uuid}/summary`, {}, signature);
}

export function login(signature: string): Promise<UserType> {
  return apiRequest("auth/login", {
    method: "POST",
    body: JSON.stringify({ signature }),
  });
}

export async function postCampaign(
  data: CampaignCreationData,
  tokenDecimals: number,
  signature: string
): Promise<CampaignType> {
  const campaign = await apiRequest(
    "campaigns",
    {
      method: "POST",
      body: JSON.stringify(campaignToApiData(data, tokenDecimals)),
    },
    signature
  );
  return campaignFromApiData(campaign);
}

export async function refreshCampaign(uuid: String): Promise<CampaignType> {
  const campaign = await apiRequest(`campaigns/${uuid}/refresh`, {
    method: "PATCH",
  });
  return campaignFromApiData(campaign);
}

export async function getTweets(
  campaignUuid?: string,
  signature?: string
): Promise<TweetType[]> {
  const query = campaignUuid ? `?campaign_uuid=${campaignUuid}` : "";
  return apiRequest(`tweets${query}`, {}, signature);
}

export async function postTweet(
  tweetId: string,
  campaignUuid: string,
  signature: string
): Promise<TweetType> {
  return apiRequest(
    `campaigns/${campaignUuid}/tweets`,
    {
      method: "POST",
      body: JSON.stringify({ tweetId }),
    },
    signature
  );
}

export async function distributeRewards(
  campaignUuid: string,
  rewards: RewardsCreationData[],
  signature: string
) {
  const serializableRewards = rewards.map((r) => ({
    shillerId: r.shillerId,
    amount: r.amount.toString(),
  }));
  return apiRequest(
    `campaigns/${campaignUuid}/finalize`,
    {
      method: "POST",
      body: JSON.stringify(serializableRewards),
    },
    signature
  );
}
