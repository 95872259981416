export interface AddressesType {
  factory: `0x${string}`;
}

export const ADDRESSES: Record<number, AddressesType> = {
  10: {
    factory: "0x8E785CE881e1acccd00c3Bd2d5007ab91461ca85",
  },
  31337: {
    factory: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
  },
};
