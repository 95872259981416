import styled from "styled-components";

import arrowDown from "../assets/ui/arrow.svg";
import { useState } from "react";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const StyledDropdown = styled.button`
  height: 4.8rem;
  padding: 0 2.4rem;
  font-size: 1.6rem;
  font-weight: 400;
  background: var(--bg);
  color: var(--main);
  border: 1px solid var(--border);
  width: 100%;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
    border-color: var(--main);
  }
`;

const Icon = styled.img`
  height: 2.8rem;
  margin-right: 1rem;
`;

const Label = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--sub);
  flex: 1;
  text-align: left;
`;

interface ArrowProps {
  $rotate: boolean;
}

const Arrow = styled.img<ArrowProps>`
  height: 1rem;
  transition: transform 0.2s ease-in-out;
  transform: ${({ $rotate }) => ($rotate ? "rotate(180deg)" : "rotate(0deg)")};
`;

const Options = styled.div`
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  width: 100%;
  background: var(--bg);
  border: solid 1px var(--border);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1;
`;

const Option = styled.button`
  padding: 0 2.85rem;
  cursor: pointer;
  width: 100%;
  height: 4.8rem;
  display: flex;
  align-items: center;

  &:hover {
    background: var(--secondary);
  }
`;

export interface DropdownOptionType {
  value: string;
  icon?: string;
}

interface Props {
  options: DropdownOptionType[];
  selectedOption: DropdownOptionType;
  setSelectedOption: (option: string) => void;
}

const Dropdown = ({ options, selectedOption, setSelectedOption }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <StyledDropdown
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        {selectedOption.icon && <Icon src={selectedOption.icon} />}
        <Label>{selectedOption.value}</Label>
        <Arrow src={arrowDown} $rotate={isOpen} />
      </StyledDropdown>
      {isOpen && (
        <Options>
          {options.map((option) => (
            <Option
              key={option.value}
              onClick={() => {
                setSelectedOption(option.value);
                setIsOpen(false);
              }}
            >
              {option.icon && <Icon src={option.icon} />}
              <Label>{option.value}</Label>
            </Option>
          ))}
        </Options>
      )}
    </Container>
  );
};

export default Dropdown;
