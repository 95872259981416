import styled from "styled-components";
import { CampaignType, formatCampaignAmount } from "../../app/types/campaign";
import Container from "./Container";
import useApproval from "../../app/hooks/use-approval";
import Button from "../../components/Button";
import useFundCampaign from "../../app/hooks/use-fund-campaign";
import { ErrorMessage } from "../../components/utils";
import useTokenBalance from "../../app/hooks/use-token-balance";
import { formatScaled } from "../../app/utils";
import useRefreshCampaign from "../../app/hooks/use-refresh-campaign";
import { useEffect } from "react";

type Props = {
  campaign: CampaignType;
};

const Explanation = styled.p`
  font-size: 1.6rem;
`;
const ButtonContainer = styled.div`
  max-width: 500px;
`;

export default function Fund({ campaign }: Props) {
  const { isApproved, approve, isApprovePending, approveError } = useApproval(
    campaign.tokenAddress
  );
  const {
    fundCampaign,
    isFundCampaignPending,
    isFundCampaignTxPending,
    fundCampaignError,
    isCampaignFunded,
    isFundCampaignSuccess,
  } = useFundCampaign(campaign);
  const { refreshCampaign, isRefreshCampaignPending } =
    useRefreshCampaign(campaign);

  useEffect(() => {
    if (isFundCampaignSuccess || isCampaignFunded) refreshCampaign();
  }, [isFundCampaignSuccess, isCampaignFunded, refreshCampaign]);

  const amountApproved = isApproved(campaign.amount);
  const { data: balance } = useTokenBalance(campaign.tokenAddress);
  const balanceTooLowError =
    balance !== undefined && balance < BigInt(campaign.amount)
      ? new Error(
          `Your wallet has less than ${formatScaled(
            campaign.amount,
            campaign.tokenDecimals
          )} ${campaign.tokenSymbol} (${formatScaled(
            balance,
            campaign.tokenDecimals
          )} ${campaign.tokenSymbol})`
        )
      : null;

  const handleClick = () => {
    if (!amountApproved) approve();
    else fundCampaign();
  };

  if (campaign.status !== "pending") return null;

  return (
    <Container>
      <Container.Wrapper>
        <Container.Header>Campaign funding</Container.Header>
        {campaign.status !== "pending" ? (
          <>
            <Explanation>
              Your campaign has been successfully funded. The campaign contract
              address is {campaign.campaignAddress}
            </Explanation>
          </>
        ) : isCampaignFunded ? (
          <>
            <Explanation>
              The campaign has been funded on-chain and the backend is currently
              indexing it.
            </Explanation>
            <ButtonContainer>
              <Button
                primary={true}
                action={refreshCampaign}
                wide={true}
                disabled={isRefreshCampaignPending}
              >
                Refresh
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <Explanation>
              You need to fund the campaign with{" "}
              {formatCampaignAmount(campaign, false)} on-chain to make it
              active.
            </Explanation>
            <ButtonContainer>
              <Button
                primary={true}
                action={handleClick}
                wide={true}
                disabled={
                  isApprovePending ||
                  isFundCampaignPending ||
                  (amountApproved && !!balanceTooLowError) ||
                  (isFundCampaignSuccess && isFundCampaignTxPending)
                }
              >
                {amountApproved ? "Fund" : "Approve funding"}
              </Button>
              {!isCampaignFunded && (
                <ErrorMessage
                  errors={[balanceTooLowError, approveError, fundCampaignError]}
                />
              )}
            </ButtonContainer>
          </>
        )}
      </Container.Wrapper>
    </Container>
  );
}
