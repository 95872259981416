import styled from "styled-components";
import Button from "../../components/Button";
import { DATE_FORMAT } from "../../app/constants";
import dateFormat from "dateformat";
import ConnectWallet from "../../components/ConnectWallet";
import { CampaignType } from "../../app/types/campaign";
import useTweets from "../../app/hooks/use-tweets";
import { tweetUrl } from "../../app/types/tweet";
import { useAccount } from "wagmi";
import Container from "./Container";

const SubmissionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;
  width: 100%;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const Submission = styled.div`
  display: flex;
  align-items: center;
  padding: 2.4rem;
  border: 1px solid var(--border);

  @media (max-width: 900px) {
    padding: 1.2rem;
  }
`;

const StyledDate = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  flex: 1;
`;

const EmptyText = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  color: var(--sub);
`;

interface Props {
  campaign: CampaignType;
}

const Submissions = ({ campaign }: Props) => {
  const { address, isConnected } = useAccount();
  const { tweets } = useTweets({ campaignId: campaign.privateUuid });

  const admin = campaign.manager.address === address;

  return (
    <Container.Wrapper>
      <Container.Header>
        {admin ? "All submissions" : "Your submissions"}
      </Container.Header>
      {!isConnected && (
        <>
          <EmptyText>Connect your wallet to view submissions</EmptyText>
          <div style={{ margin: "auto" }}>
            <ConnectWallet />
          </div>
        </>
      )}
      {tweets?.length === 0 && <EmptyText>No tweets yet</EmptyText>}
      {tweets && tweets.length > 0 && isConnected && (
        <SubmissionGrid>
          {tweets.map((submission) => (
            <Submission key={submission.tweetId}>
              <StyledDate>
                {dateFormat(submission.timestamp, DATE_FORMAT)}
              </StyledDate>
              <Button
                action={() => {
                  window.open(tweetUrl(submission), "_blank")?.focus();
                }}
              >
                View Post
              </Button>
            </Submission>
          ))}
        </SubmissionGrid>
      )}
    </Container.Wrapper>
  );
};

export default Submissions;
