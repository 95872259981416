import styled from "styled-components";
import Button from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import useCampaign from "../../app/hooks/use-campaign";
import useCampaignSummary from "../../app/hooks/use-campaign-summary";
import useDistribution, {
  DistributionType,
} from "../../app/hooks/use-distribution";
import { formatScaled } from "../../app/utils";
import useDistributeRewards from "../../app/hooks/use-distribute-rewards";
import { ErrorMessage } from "../../components/utils";

const Header = styled.h1`
  font-size: 3.6rem;
  font-weight: 500;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 2.2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2.4rem;

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
`;

const AccountsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

const Account = styled.div<{ $included: boolean }>`
  width: 100%;
  padding: 2.4rem;
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: ${(props) => (props.$included ? 1 : 0.5)};
  cursor: ${(props) => (props.$included ? "auto" : "not-allowed")};

  @media (max-width: 900px) {
    padding: 1.2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
`;

const Item = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  flex: 1;
`;

const ItemLink = styled.a`
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--main);
  text-decoration: underline;
  cursor: pointer;
  flex: 1;
`;

const StyledDistributePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--bg);
  padding: 4.8rem;
  border: 1px solid var(--border);
  gap: 4.8rem;

  @media (max-width: 900px) {
    padding: 1.2rem;
    gap: 2.4rem;
  }
`;

const DistributePage = () => {
  const navigate = useNavigate();
  const campaignId = useParams().campaignId;
  const { campaign, isCampaignLoading, campaignError } =
    useCampaign(campaignId);

  const { campaignSummary } = useCampaignSummary(campaignId);
  const {
    distribution,
    setDistribution,
    eligibleAccounts,
    rewards,
    getReward,
    isEligible,
    toggleEligible,
  } = useDistribution(campaign?.amount, campaignSummary?.users);

  const {
    distributeRewards,
    isDistributingRewardsPending,
    distributeRewardsError,
  } = useDistributeRewards(campaign);

  if (campaignError?.message.includes("404")) {
    navigate("/404");
    return null;
  }

  if (isCampaignLoading || !campaign || !campaignSummary)
    return <div>Loading...</div>;

  const { users } = campaignSummary;

  return (
    <StyledDistributePage>
      <Container>
        <Header>Select Distribution Strategy</Header>
        <ButtonContainer>
          {Object.values(DistributionType).map((type) => (
            <Button
              key={type}
              action={() => setDistribution(type)}
              primary={distribution === type}
            >
              {type}
            </Button>
          ))}
        </ButtonContainer>
        <AccountsContainer>
          {users?.map((user) => (
            <Account
              key={user.address}
              $included={eligibleAccounts.includes(user.twitterHandle)}
            >
              <ItemLink href={`https://x.com/${user.twitterHandle}`}>
                {user.twitterHandle}
              </ItemLink>
              <Item>{`Tweets: ${user.totalTweets.toLocaleString()}`}</Item>
              <Item>{`Likes: ${user.totalLikes.toLocaleString()}`}</Item>
              <Item>{`Impressions: ${user.totalImpressions.toLocaleString()}`}</Item>
              <Item>{`${campaign.tokenSymbol}: ${formatScaled(
                getReward(user),
                campaign.tokenDecimals
              )}`}</Item>
              <Button onlyDesktop action={() => toggleEligible(user)}>
                {isEligible(user) ? "Remove" : "Include"}
              </Button>
            </Account>
          ))}
        </AccountsContainer>
        <Button
          wide
          primary
          disabled={isDistributingRewardsPending}
          action={() => distributeRewards(rewards)}
        >
          Distribute {campaign.tokenSymbol}
        </Button>
        <ErrorMessage errors={[distributeRewardsError]} />
      </Container>
    </StyledDistributePage>
  );
};

export default DistributePage;
