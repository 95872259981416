import styled from "styled-components";
import logo from "../assets/logo.jpg";
import { Link } from "react-router-dom";

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledLogo = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
`;

const LogoText = styled.div`
  font-size: 2.4rem;
  font-weight: 500;
  margin-left: 1.2rem;
`;

const Logo = () => {
  return (
    <LogoContainer to="/">
      <StyledLogo src={logo} alt="Logo" />
      <LogoText>shillio</LogoText>
    </LogoContainer>
  );
};

export default Logo;
