import styled from "styled-components";
import Button from "../../components/Button";
import { DOCS_LINK } from "../../app/links";
import { useNavigate } from "react-router-dom";
import { CREATE_ROUTE } from "../../app/routes";

const StyledHero = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12rem 0;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media (max-width: 900px) {
    gap: 1.6rem;
  }
`;

const Header = styled.h1`
  font-size: 5.5rem;
  font-weight: 500;
  margin-bottom: 1.6rem;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 4.5rem;
  }
`;

const SubHeader = styled.h2`
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 2.4rem;
  max-width: 75rem;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4.8rem;
  margin-bottom: 4.8rem;

  @media (max-width: 900px) {
    gap: 1.6rem;
  }
`;

const Hero = () => {
  const navigate = useNavigate();

  return (
    <StyledHero>
      <Content>
        <Header>Work for your bags and collect bounties</Header>
        <SubHeader>
          Where projects gain influence and community members get paid
        </SubHeader>
        <ButtonContainer>
          <Button
            action={() => window.open(DOCS_LINK, "_blank")?.focus()}
            primary
          >
            How it works
          </Button>
          <Button action={() => navigate(`/${CREATE_ROUTE}`)}>
            Create campaign
          </Button>
        </ButtonContainer>
      </Content>
    </StyledHero>
  );
};

export default Hero;
