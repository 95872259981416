import { useAccount } from "wagmi";
import CHAIN_DATA from "../chain-data";

const useChainData = (chainIdSpecified?: number) => {
  let { chainId } = useAccount();

  chainId = chainIdSpecified || chainId;

  if (!chainId) return null;

  const chainData = CHAIN_DATA[chainId];
  if (!chainData) return null;

  return chainData;
};

export default useChainData;
