import { useQuery } from "@tanstack/react-query";
import { useAuth } from "./use-auth";
import { TweetType } from "../types/tweet";
import { getTweets } from "../api";

type Filter = {
  campaignId?: string;
};

export default function useTweets({ campaignId }: Filter = {}) {
  const { signature, loggedIn } = useAuth();
  const {
    data: tweets,
    error: tweetsError,
    isLoading: areTweetsLoading,
  } = useQuery<TweetType[]>({
    queryKey: ["tweets", { campaignId }],
    queryFn: () => getTweets(campaignId, signature),
    enabled: loggedIn,
  });
  return { tweets, tweetsError, areTweetsLoading };
}
