import {
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import useAddresses from "./use-addresses";
import { CampaignType } from "../types/campaign";
import factoryAbi from "../abis/factory-abi";
import { uuidToBigint } from "../utils";
import { zeroAddress } from "viem";

export default function useFundCampaign(campaign: CampaignType) {
  const addresses = useAddresses();
  const {
    data: hash,
    writeContract,
    error: fundCampaignError,
    isPending: isFundCampaignPending,
    isSuccess: isFundCampaignSuccess,
  } = useWriteContract();
  const { isPending: isFundCampaignTxPending } = useWaitForTransactionReceipt({
    hash,
  });

  const { data: campaignAddress } = useReadContract({
    abi: factoryAbi,
    functionName: "campaignIdToAddress",
    address: addresses?.factory,
    args: [uuidToBigint(campaign.uuid)],
  });
  const isCampaignFunded = campaignAddress !== zeroAddress;

  const fundCampaign = () => {
    if (!addresses) return;

    writeContract({
      abi: factoryAbi,
      functionName: "createCampaign",
      address: addresses?.factory,
      args: [
        uuidToBigint(campaign.uuid),
        campaign.tokenAddress,
        BigInt(campaign.amount),
        BigInt(campaign.startTimestamp.getTime() / 1000),
        BigInt(campaign.endTimestamp.getTime() / 1000),
      ],
    });
  };

  return {
    fundCampaign,
    isCampaignFunded,
    fundCampaignError,
    isFundCampaignPending,
    isFundCampaignTxPending,
    isFundCampaignSuccess,
  };
}
