import styled from "styled-components";
import LandingPage from "./pages/landing/LandingPage";
import Background from "./components/Background";
import { Outlet, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import {
  CAMPAIGN_ROUTE,
  CREATE_ROUTE,
  DISTRIBUTE_ROUTE,
  LOGIN_ROUTE,
} from "./app/routes";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import CampaignPage from "./pages/campaign/CampaignPage";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import CreatePage from "./pages/create/CreatePage";
import DistributePage from "./pages/distribute/DistributePage";
import TwitterLogin from "./pages/TwitterLogin";
import ComingSoon from "./components/ComingSoon";

const StyledApp = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem;

  @media (max-width: 900px) {
    padding: 1.2rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 130rem;
  height: 100%;
`;

const Layout = () => {
  return (
    <StyledApp>
      <Background />
      <Content>
        <Header />
        <Outlet />
        <Footer />
      </Content>
      <ScrollToTop />
      <ComingSoon />
    </StyledApp>
  );
};

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<LandingPage />} />

        <Route path={CAMPAIGN_ROUTE}>
          <Route path=":campaignId" element={<CampaignPage />} />
        </Route>

        <Route path={CREATE_ROUTE} element={<CreatePage />} />

        <Route path={LOGIN_ROUTE} element={<TwitterLogin />} />

        <Route path={DISTRIBUTE_ROUTE}>
          <Route path=":campaignId" element={<DistributePage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default App;
