import styled from "styled-components";

export const StyledButton = styled.button<{
  $primary?: boolean;
  $disabled?: boolean;
  $wide?: boolean;
  $onlyDesktop?: boolean;
}>`
  height: 4.8rem;
  padding: 0 2.4rem;
  border-radius: 0;
  font-size: 1.6rem;
  font-weight: 500;
  background: ${(props) => (props.$primary ? "var(--primary)" : "var(--bg)")};
  color: ${(props) => (props.$primary ? "var(--bg)" : "var(--main)")};
  border: 1px solid
    ${(props) => (props.$primary ? "var(--primary)" : "var(--main)")};
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  transition: all 0.2s;
  min-width: 16rem;
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
  width: ${(props) => (props.$wide ? "100%" : "auto")};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    display: ${(props) => (props.$onlyDesktop ? "none" : "block")};
    min-width: 12rem;
    height: 4.4rem;
  }
`;

interface Props {
  children: React.ReactNode;
  action?: () => void;
  link?: string;
  primary?: boolean;
  disabled?: boolean;
  wide?: boolean;
  onlyDesktop?: boolean;
}

const Button = ({
  children,
  action,
  link,
  primary,
  disabled,
  wide,
  onlyDesktop,
}: Props) => {
  return (
    <>
      {action && (
        <StyledButton
          $onlyDesktop={onlyDesktop}
          onClick={() => {
            if (disabled) return;
            action && action();
          }}
          $primary={primary}
          $disabled={disabled}
          $wide={wide}
        >
          {children}
        </StyledButton>
      )}
      {link && (
        <StyledButton
          as="a"
          href={link}
          $primary={primary}
          $disabled={disabled}
          $wide={wide}
        >
          {children}
        </StyledButton>
      )}
    </>
  );
};

export default Button;
