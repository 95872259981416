import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CampaignType } from "../types/campaign";
import { refreshCampaign as refreshCampaignRequest } from "../api";

export default function useRefreshCampaign(campaign: CampaignType) {
  const queryClient = useQueryClient();
  const {
    mutate: refreshCampaign,
    error: refreshCampaignError,
    isPending: isRefreshCampaignPending,
  } = useMutation({
    mutationFn: () => refreshCampaignRequest(campaign.privateUuid),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["campaign", { id: campaign.privateUuid }],
      }),
  });

  return { refreshCampaign, refreshCampaignError, isRefreshCampaignPending };
}
