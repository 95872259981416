interface ChainDataType {
  explorer: string;
}

const CHAIN_DATA: Record<number, ChainDataType> = {
  1: {
    explorer: "https://etherscan.io",
  },
  10: {
    explorer: "https://optimistic.etherscan.io",
  },
  42161: {
    explorer: "https://arbiscan.io",
  },
  8453: {
    explorer: "https://explorer.baseledger.com",
  },
  31337: {
    explorer: "https://etherscan.io",
  },
};

export default CHAIN_DATA;
