import styled from "styled-components";
import { StyledInput } from "../../components/Input";
import Button from "../../components/Button";
import {
  CHAIN_ICONS,
  CHAIN_IDS,
  REVERSE_CHAIN_IDS,
  SUPPORTED_CHAINS,
} from "../../app/constants";
import Dropdown from "../../components/Dropdown";
import useCreateCampaign from "../../app/hooks/use-create-campaign";
import ConnectWallet from "../../components/ConnectWallet";
import useAuthStore from "../../app/stores/auth-store";
import { ErrorText } from "../../components/utils";
import { useAccount } from "wagmi";

const StyledCreatePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--bg);
  padding: 4.8rem;
  border: 1px solid var(--border);
  gap: 2.4rem;

  @media (max-width: 900px) {
    padding: 2.4rem;
  }
`;

const Inputs = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 2.4rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 2.4rem;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  label {
    font-size: 1.6rem;
  }
`;

const InputWithError = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const PreviewIcon = styled.img`
  height: 2.8rem;
  width: 2.8rem;
  position: absolute;
  top: 50%;
  right: 1.6rem;
  transform: translateY(-50%);
  border-radius: 50%;
  box-shadow: -1rem 0 1rem white;
`;

const PreviewText = styled.span`
  height: 100%;
  position: absolute;
  display: inline-flex;
  align-items: center;
  right: 1.6rem;
`;

const CreatePage = () => {
  const {
    register,
    watch,
    onSubmit,
    setValue,
    isValid,
    errors,
    isPending,
    submittedCampaign,
    submissionError,
    tokenSymbol,
  } = useCreateCampaign();
  const { address } = useAccount();
  const { getSignature } = useAuthStore();

  const signature = address && getSignature(address);

  const imageUrl = watch("logoUrl");
  const chainId = watch("chainId");
  const chainName = REVERSE_CHAIN_IDS[chainId];

  return (
    <StyledCreatePage>
      <Container>
        <Header>Create Campaign</Header>
        <Inputs>
          <InputWithError>
            <InputContainer>
              <StyledInput
                placeholder="Enter project logo image URL*"
                {...register("logoUrl")}
              />
              {imageUrl && <PreviewIcon src={imageUrl} alt="Preview" />}
            </InputContainer>
            {errors.logoUrl && <ErrorText>{errors.logoUrl.message}</ErrorText>}
          </InputWithError>
          <InputWithError>
            <InputContainer>
              <StyledInput
                placeholder="Enter project name*"
                {...register("projectName")}
              />
            </InputContainer>
            {errors.projectName && (
              <ErrorText>{errors.projectName.message}</ErrorText>
            )}
          </InputWithError>
          <InputWithError>
            <InputContainer>
              <StyledInput
                placeholder="Enter project URL*"
                {...register("projectUrl")}
              />
            </InputContainer>
            {errors.projectUrl && (
              <ErrorText>{errors.projectUrl.message}</ErrorText>
            )}
          </InputWithError>
          <InputWithError>
            <InputContainer>
              <StyledInput
                placeholder="Enter description*"
                {...register("description")}
              />
            </InputContainer>
            {errors.description && (
              <ErrorText>{errors.description.message}</ErrorText>
            )}
          </InputWithError>
          <InputWithError>
            <InputContainer>
              <StyledInput
                placeholder="Enter token address*"
                {...register("tokenAddress")}
              />
              {tokenSymbol && <PreviewText>{tokenSymbol}</PreviewText>}
            </InputContainer>
            {errors.tokenAddress && (
              <ErrorText>{errors.tokenAddress.message}</ErrorText>
            )}
          </InputWithError>
          <InputWithError>
            <InputContainer>
              <StyledInput
                placeholder="Enter token amount*"
                {...register("amount")}
              />
            </InputContainer>
            {errors.amount && <ErrorText>{errors.amount.message}</ErrorText>}
          </InputWithError>
          <InputWithError>
            <InputContainer>
              <StyledInput
                type="datetime-local"
                placeholder="Enter start time (yyyy/mm/dd hh:mm)*"
                {...register("startTimestamp")}
              />
            </InputContainer>
            {errors.startTimestamp && (
              <ErrorText>{errors.startTimestamp.message}</ErrorText>
            )}
          </InputWithError>
          <InputWithError>
            <InputContainer>
              <StyledInput
                type="datetime-local"
                placeholder="Enter end date (yyyy/mm/dd hh:mm)*"
                {...register("endTimestamp")}
              />
            </InputContainer>
            {errors.endTimestamp && (
              <ErrorText>{errors.endTimestamp.message}</ErrorText>
            )}
          </InputWithError>
          <InputWithError>
            <InputContainer>
              <Dropdown
                options={SUPPORTED_CHAINS.map((chain) => ({
                  value: chain,
                  icon: CHAIN_ICONS[chain],
                }))}
                selectedOption={{
                  value: chainName,
                  icon: CHAIN_ICONS[chainName],
                }}
                setSelectedOption={(v) => setValue("chainId", CHAIN_IDS[v])}
              />
            </InputContainer>
            {errors.chainId && <ErrorText>{errors.chainId.message}</ErrorText>}
          </InputWithError>
          <CheckboxContainer>
            <label>Is this campaign private?</label>
            <input type="checkbox" {...register("isPrivate")} />
          </CheckboxContainer>
        </Inputs>
        {!signature && <ConnectWallet />}
        {signature && !submittedCampaign && (
          <>
            <Button primary disabled={!isValid || isPending} action={onSubmit}>
              Create Campaign
            </Button>
            {submissionError && (
              <ErrorText>{submissionError.message}</ErrorText>
            )}
          </>
        )}
      </Container>
    </StyledCreatePage>
  );
};

export default CreatePage;
