import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./use-auth";
import { postTweet } from "../api";
import { useEffect, useState } from "react";
import { getTweetId } from "../utils";
import { CampaignType } from "../types/campaign";

export default function usePostTweet(campaign: CampaignType) {
  const [tweetUrl, setTweetUrl] = useState("");
  const [tweetId, setTweetId] = useState("");
  const [urlError, setUrlError] = useState("");
  const queryClient = useQueryClient();
  const { signature } = useAuth();

  const { mutate, isError, error, isPending, reset } = useMutation({
    mutationFn: async () => {
      if (!signature) throw new Error("not logged in");
      if (!tweetId) throw new Error("Tweet id not set");

      const tweet = await postTweet(tweetId, campaign.privateUuid, signature);
      queryClient.invalidateQueries({
        queryKey: ["tweets", { campaignId: campaign.privateUuid }],
      });
      setTweetUrl("");
      setTweetId("");
      return tweet;
    },
  });

  useEffect(() => {
    reset();
    if (!tweetUrl) {
      setUrlError("");
      return;
    }
    try {
      setTweetId(getTweetId(tweetUrl));
      setUrlError("");
    } catch (e) {
      if (e instanceof Error) setUrlError(e.message);
    }
  }, [tweetUrl, reset]);

  return {
    postTweet: mutate,
    urlError,
    tweetId,
    tweetUrl,
    setTweetUrl,
    isError,
    error,
    isPending,
  };
}
